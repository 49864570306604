<template>
  <div class="AddCorrect">
    <div class="content-header">
      
      <div class="content-title">考试试卷批改
        <a-button type="primary" @click="_deriveData">导出数据</a-button>
      </div>
      <a-table
        :columns="columns"
        :dataSource="tableData"
        :pagination="false"
        bordered
        style="margin: 15px 0;"
      ></a-table>
      <div class="content-title">
        考试批改人：<span v-for="(item, index) in userList">{{
          index != 0 ? '，' + item.title : item.title
        }}</span>
        <a-button type="primary" class="add-correct" ghost
          >添加批改人
          <person
            v-bind="personObj"
            style="position: absolute;top:0;left:0;opacity: 0"
            @getData="getData"
          ></person
        ></a-button>
      </div>
    </div>

    <div class="content-header" style="margin-top: 30px;">
      <div class="flex" style="justify-content: end;margin-bottom: 15px;">
        <span>姓名：</span>
        <a-input
          placeholder="请输入..."
          v-model="searchName"
          style="width: 200px;margin-right: 15px;"
        />
        <a-button type="primary" @click="_search">查询</a-button>
      </div>
      <div class="content-title">参考人数 ( 共{{ total }}人 )</div>
      <a-table
        :columns="correctColumns"
        :dataSource="correctData"
        :pagination="false"
        bordered
        style="margin: 15px 0;"
      >
        <div slot="action" slot-scope="text, record">
          <i
            class="iconfont icon-tubiao_pigai icon-btn"
            style="margin-right: 8px"
            :style="
              record.Status == 2
                ? 'color: #7672F9'
                : record.Status == 1
                ? 'color: #FF7F38'
                : ''
            "
            @click="_skipCorrect(record)"
          ></i>
          <a-popconfirm
            placement="top"
            cancelText="取消"
            okText="确定"
            @confirm="_del(record)"
          >
            <template slot="title">
              <p>确定删除么？</p>
            </template>
            <i class="iconfont icon-shanchu- icon-btn"></i>
          </a-popconfirm>
        </div>
      </a-table>
      <div style="margin: 20px 0;" v-if="total > 10">
        <a-pagination
          showQuickJumper
          :defaultCurrent="1"
          :total="total"
          @change="_PageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import person from 'components/SelectPersonnel/SelectPersonnel' //选人
var user = JSON.parse(window.sessionStorage.getItem('userInfo'))
export default {
  name: 'AddCorrect',
  components: { person },
  data () {
    return {
      columns: [
        {
          title: '考试名称',
          dataIndex: 'Title',
          align: 'center'
        },
        {
          title: '试卷分类',
          dataIndex: 'CategoryName',
          align: 'center'
        },
        {
          title: '应考人数',
          dataIndex: 'ShareCount',
          align: 'center'
        },
        {
          title: '实考人数',
          dataIndex: 'RealCount',
          align: 'center'
        },
        {
          title: '开始时间',
          dataIndex: 'StartTime',
          align: 'center'
        },
        {
          title: '结束时间',
          dataIndex: 'EndTime',
          align: 'center'
        },
        {
          title: '创建人',
          dataIndex: 'CreateUserName',
          align: 'center'
        }
      ],
      correctColumns: [
        {
          title: '序号',
          dataIndex: 'sNumber',
          align: 'center'
        },
        {
          title: '用户名',
          dataIndex: 'UserName',
          align: 'center'
        },
        {
          title: '所属部门',
          dataIndex: 'SectionName',
          align: 'center'
        },
        {
          title: '总分',
          dataIndex: 'z_Score',
          align: 'center'
        },
        {
          title: '得分',
          dataIndex: 'UserScore',
          align: 'center'
        },
        {
          title: '交卷时间',
          dataIndex: 'SubmiTime',
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          scopedSlots: { customRender: 'action' }
        }
      ],
      tableData: [],
      correctData: [],
      page: 1,
      personObj: {
        rank: 0, //职级 0为不选，1为选
        nums: 100, //选的数量
        department: 0, //0不选部门，1为都选（人 部门都选），2为只选部门，选人
        contacts: '', //常用联系人recent 不要就传其他
        selectArr: this.userList //要传入的数据
      },
      userList: [],
      infoData: '',
      total: 0,
      searchName: ''
    }
  },
  created () {
    let user = JSON.parse(window.sessionStorage.getItem('userInfo'))
    this.$axios.get(
      5836106,
      { Qid: this.$route.query.id, Page: this.page },
      res => {
        if (res.data.code == 1) {
          this.infoData = res.data
          res.data.qtInfo.key = 225836 //antd表格参数要加key不然页面会报错
          this.tableData.push(res.data.qtInfo)
          this.correctData = res.data.UserList
          this.userList = res.data.qtInfo.AllowId
          this.total = res.data.count
          this.personObj.selectArr = this.userList
        }
      }
    )
  },
  methods: {
    _search () {
      this.$axios.get(
        5836106,
        { Qid: this.$route.query.id, Page: 1, Keyword: this.searchName },
        res => {
          this.infoData = res.data
          this.correctData = res.data.UserList
          this.userList = res.data.qtInfo.AllowId
          this.total = res.data.count
          this.$message.destroy()
        }
      )
    },
    _info (page) {
      this.$message.loading('加载中...', 0)
      this.$axios.get(
        5836106,
        { Qid: this.$route.query.id, Page: this.page },
        res => {
          this.infoData = res.data
          res.data.qtInfo.key = 225836 //antd表格参数要加key不然页面会报错
          if (page != 666) {
            this.tableData.push(res.data.qtInfo)
          }
          this.correctData = res.data.UserList
          this.userList = res.data.qtInfo.AllowId
          this.total = res.data.count
          this.$message.destroy()
          if (res.data.code == 1) {
            this.page += 1
          }
        }
      )
    },
    _PageChange (pageNumber) {
      this.page = pageNumber
      this._info(666)
    },
    _skipCorrect (val) {
      this.$router.push({
        path: '/correctQuestionnaire',
        query: { id: this.$route.query.id, uid: val.UserId }
      })
    },
    _del (val) {
      this.$axios.post(
        5836108,
        {
          Qid: this.$route.query.id,
          UserId: user.uid,
          deleteUserId: val.UserId
        },
        res => {
          if (res.data.code == 1) {
            this.correctData.splice(val.key - 1)
            this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg)
          }
        }
      )
    },
    getData (val) {
      this.$axios.post(
        5836107,
        {
          Qid: this.$route.query.id,
          UserId: user.uid,
          allowUserList: JSON.stringify(val)
        },
        res => {
          if (res.data.code == 1) {
            this.userList = val
            this.personObj.selectArr = this.userList
            this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg)
          }
        }
      )
    },
    _deriveData () {
      this.$message.loading('文件下载中...', 0)
      //数据导出
      this.$axios.get(
        5836115,
        {
          Qid: this.$route.query.id,
          Type: 1,
          UserId: user.uid
        },
        res => {
          if (res.data.code == 1) {
            window.open(res.data.url)
            this.$message.destroy()
          } else {
            this.$message.error(res.data.msg)
            this.$message.destroy()
          }
        }
      )
    },
  }
}
</script>

<style scoped lang="less">
.AddCorrect {
  .content-header {
    padding: 20px;
    background: #fff;
    border-radius: 4px;
    .content-title {
      font-size: 18px;
      color: #333;
      .add-correct {
        position: relative;
        margin-left: 10px;
      }
    }
  }
  .icon-btn {
    font-size: 20px;
    cursor: pointer;
    color: #a9a9a9;
  }
}
</style>
